import dayjs from 'dayjs';
import { isExpiredDay } from '@/utils/validateTimeRanges';
export const licenseOrg = /^\s*[\u4e00-\u9fa5]{5,}\s*$/;
export const DriverAtuhStatus = {
  Unknow: -1,
  Waiting: 1, //待审核
  Finish: 2, //已审核
  Fail: 3 //审核不通过
};

export const steps = ['身份证', '驾驶证', '从业资格证', '认证结果'];
export const isBeforeThan = (initValue, startDate) => {
  if (!initValue || !startDate) return true;
  const mInit = dayjs(initValue)
    .startOf('day')
    .valueOf();
  const mStart = dayjs(startDate)
    .startOf('day')
    .valueOf();
  return mInit <= mStart;
};

export const getErrorByRules = (rules, value) => {
  if (!rules) return '';
  let arr = Array.isArray(rules) ? rules : [rules];
  let error = '';
  arr.forEach(({ validator, message }) => {
    if (error || !validator) return;
    const pass = validator(value);
    if (!pass) {
      error = message || '有误';
    }
  });
  return error;
};

export const driverStepFormOne = isReview => {
  console.log(isReview);
  return {
    // 表单数据流
    driverPhone: {
      label: '手机号',
      autosize: false,
      type: 'number',
      disabled: true,
      required: true,
      hidden: false,
      rules: [{ required: true, message: '' }]
    },
    name: {
      label: '身份证姓名',
      autosize: false,
      type: 'text',
      disabled: true,
      required: true,
      hidden: false,
      rules: [
        { required: true, message: '' },
        { pattern: /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{1,20}$/, message: '非合法名称' }
      ]
    },
    idCard: {
      hidden: false,
      label: '身份证号码',
      autosize: false,
      type: 'text',
      disabled: true,
      required: true,
      rules: [{ required: true, message: '' }]
    },
    address: {
      label: '身份证地址',
      autosize: true,
      type: 'textarea',
      disabled: false,
      required: true,
      hidden: false,
      rules: [
        { required: true, message: '' },
        { validator: item => item.length >= 5, message: '不少于5位字符' }
      ]
    },
    idCardStartDate: {
      label: '身份证有效期',
      custom: true,
      required: true,
      hidden: false
    },
    idCardIssueOrganization: {
      label: '签发机关',
      autosize: true,
      type: 'textarea',
      disabled: false,
      required: !!isReview,
      hidden: false,
      rules: [
        {
          validator: value => {
            if (value && !licenseOrg.test(value)) return false;
            return true;
          },
          message: '不少于5位中文字符'
        }
      ]
    }
  };
};

export const driverStepFormTwo = (timeDataRef, isReview) => ({
  driverLicenseNumber: {
    label: '驾驶证编号',
    autosize: false,
    type: 'text',
    required: true,
    rules: [
      { required: true, message: '' },
      { validator: item => item.length > 0 && item.length < 21, message: '驾驶证编号不合法！' }
    ]
  },
  driveType: {
    label: '准驾车型',
    type: 'check',
    visible: false,
    autosize: false,
    required: true,
    rules: [{ required: true, message: '' }],
    options: [
      ['A2', 'B2', 'C1'],
      ['A1', 'A3', 'B1', 'C2', 'D', 'C3', 'C4', 'F', 'M', 'N', 'E']
    ]
  },
  driverLicenseIssueOrganization: {
    label: '发证机关',
    autosize: true,
    type: 'textarea',
    required: true,
    rules: [{ required: true, message: '' }]
  },
  driverLicenseEndDate: {
    label: '驾驶证有效期',
    custom: true
  },
  driverLicenseAnnualReviewDate: {
    label: '驾驶证年审有效期至',
    type: 'check',
    autosize: false,
    required: !!isReview,
    rules: [
      {
        validator: item => {
          if (item && isExpiredDay(item)) return false;
          return true;
        },
        message: '驾驶证年审已过期'
      }
    ]
  },
  driverLicenseInitialIssueDate: {
    label: '初次领证日期',
    type: 'check',
    autosize: false,
    required: !!isReview,
    rules: [
      {
        validator: item => {
          if (item && !isBeforeThan(item, Date.now())) return false;
          return true;
        },
        message: '初次领证日期≤当前时间'
      },
      {
        validator: item => {
          const { startDate } = timeDataRef;
          if (item && startDate && !isBeforeThan(item, startDate)) return false;
          return true;
        },
        message: '初次领证日期≤驾驶证有效期起始日期'
      }
    ]
  },
  fileNumber: {
    label: '驾驶证档案编号',
    autosize: false,
    type: 'text',
    required: false,
    rules: [{ validator: item => !item || item.length <= 12, message: '档案编号不超过12位字符' }]
  },
  driverLicenseAddress: {
    label: '驾驶证住址',
    autosize: true,
    type: 'textarea',
    required: false,
    rules: [{ validator: item => !item || item.length >= 5, message: '驾驶证住址不小于5位' }]
  }
});
