import { defineStore } from 'pinia';
import { getDataSessionCache, setDataSessionCache } from '@/core/cache/data';
import { DRIVER_VERSION_INFO_KEY } from '@/enums/cacheEnum';
import {
  getDriverAuthInfoApi,
  driverAuthApi,
  getDriverAuthInfoById,
  updateDriverAuthApi,
  getRealNameInfoApi,
  updateDriverByIdApi,
  afreshDriverAuthApi
} from '@api/drivers';
import { DriverAtuhStatus } from '@/constants';
import { useUserStore } from './user';
import { Toast } from 'vant';
import router from '@/router/.invoke/router';
import { formatToDateTime } from '@wlhy-web-lib/shared';

import { cloneDeep, isEmpty } from 'lodash-es';
import dayjs from 'dayjs';
import { objectCompare } from '@/utils/authMode';
const parseTimeStr = (time, isEndofMonth = false) => {
  if (!time || !dayjs(time).isValid()) return null;
  return isEndofMonth
    ? dayjs(time)
        .endOf('month')
        .valueOf() - 1000
    : dayjs(time).valueOf();
};
const parseTime = (time, format = 'yyyy-MM-dd') => {
  if (!time || !dayjs(time).isValid()) return null;
  return formatToDateTime(time, format);
};
const initData = () => ({
  address: '',
  authStatus: null,
  driveType: null,
  step: 0,
  driverId: null,
  driverLicenseEndDate: null,
  driverLicenseNumber: '',
  driverLicenseStartDate: null,
  qualificationLicenceStartDate: null,
  qualificationLicenceEndDate: null,
  driverPhone: '',
  id: null,
  idCard: '',
  idCardBackUrl: '',
  idCardFrontUrl: '',
  driverLicenseIssueOrganization: '',
  licenseUrl: '',
  name: '',
  qualificationLicenceMainUrl: '',
  qualificationNumber: '',
  refuseReason: null,
  reasonKeys: [],
  userId: 0,
  idCardStartDate: null,
  idCardEndDate: null,

  idCardIssueOrganization: null, //身份证签发机关
  driverLicenseAnnualReviewDate: null, //年检有效期
  driverLicenseInitialIssueDate: null, //驾驶证初次领证日期
  fileNumber: null, //驾驶证档案编号
  driverLicenseAddress: null, //驾驶证住址
  qualificationLicenseType: null, //
  qualificationLicenseDriveType: [], //从业资格证准驾车型
  qualificationLicenseAddress: null, //从业资格证住址
  qualificationLicenseInitialIssueDate: null, //从业资格证初次领证日期
  qualificationLicenseIssueOrganization: null, //从业资格证初次发证机关
  continuingEducationLevel: null, //继续教育等级
  continuingEducationStartDate: null,
  continuingEducationEndDate: null, //继续教育有效期
  qualificationLicenseIntegrityAssessmentRecord: null,
  qualificationLicenseIntegrityAssessmentValidFrom: null,
  qualificationLicenseIntegrityAssessmentValidTo: null
});
export const useDriverVerifiedStore = defineStore({
  id: 'driver-verified',
  state: () => ({
    verifiedInfo: Object.assign(initData(), {}),

    originVerifiedInfo: {}, // 用来比对数据
    isAmendDriverAuthInfo: false, // 表示 认证通过重新认证的状态
    // 模式认证配置
    authModeFields: {},

    showComplete: false, // 编辑要显示所要字段
    isReAuth: false // 表示 审核中点击重新认证
  }),
  actions: {
    updateAuthModeFields(fields) {
      this.authModeFields = fields;
    },

    updateIsAmendDriverAuthInfo(val) {
      this.isAmendDriverAuthInfo = val;
    },

    updateInfo(val) {
      this.verifiedInfo = Object.assign(this.verifiedInfo, val);
    },
    saveInfo() {
      // 缓存到session中
      setDataSessionCache(DRIVER_VERSION_INFO_KEY, this.verifiedInfo);
    },
    clearInfo() {
      // 重置操作
      this.verifiedInfo = initData();
    },
    async updateVerifiedInfo(callback) {
      try {
        const useInfoStore = useUserStore();
        const realNameStore = useInfoStore.realNameAuthVO;

        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '司机数据上传中...'
        });
        const params = {
          ...realNameStore,
          ...this.verifiedInfo,
          driverLicenseAnnualReviewDate: parseTimeStr(this.verifiedInfo.driverLicenseAnnualReviewDate),
          driverLicenseInitialIssueDate: parseTimeStr(this.verifiedInfo.driverLicenseInitialIssueDate),
          qualificationLicenseInitialIssueDate: parseTimeStr(this.verifiedInfo.qualificationLicenseInitialIssueDate),
          qualificationLicenseDriveType: this.verifiedInfo.qualificationLicenseDriveType.join('') || null,
          driveType: this.verifiedInfo.driveType?.split(',').join('') || null
        };

        // console.log(params);

        let res;

        if (this.isAmendDriverAuthInfo) {
          this.originVerifiedInfo.driveType = this.originVerifiedInfo.driveType.split(',').join('');
          if (objectCompare(this.originVerifiedInfo, params)) {
            this.updateInfo({ step: 3 });
            callback();
            Toast.clear();
            return;
          }
        }
        res = await updateDriverByIdApi(params);

        const { data } = res;
        if (data) {
          this.updateInfo({
            step: 3
          });
          setTimeout(() => {
            callback();
            Toast.clear();
          }, 500);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async submitVerifiedInfo(callback) {
      const useInfoStore = useUserStore();
      const realNameStore = useInfoStore.realNameAuthVO;
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '司机数据上传中...'
      });
      try {
        const params = {
          ...realNameStore,
          ...this.verifiedInfo,
          driverLicenseAnnualReviewDate: parseTimeStr(this.verifiedInfo.driverLicenseAnnualReviewDate),
          driverLicenseInitialIssueDate: parseTimeStr(this.verifiedInfo.driverLicenseInitialIssueDate),
          qualificationLicenseInitialIssueDate: parseTimeStr(this.verifiedInfo.qualificationLicenseInitialIssueDate),
          qualificationLicenseDriveType: this.verifiedInfo.qualificationLicenseDriveType.join('') || null,
          driveType: this.verifiedInfo.driveType?.split(',').join('') || null
        };
        const res = await driverAuthApi(params);
        const { data } = res;
        if (data) {
          this.updateInfo({
            step: 3
          });
          setTimeout(() => {
            callback();
            Toast.clear();
          }, 500);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async queryVerifiedInfo(callback) {
      // 根据回传数据查询是否是已撤销的审核
      const res = router?.currentRoute?.query?.driverId
        ? await getDriverAuthInfoById({ driverId: router.currentRoute.query.driverId })
        : await getDriverAuthInfoApi();

      let data = res.data || {};
      // 获取 生份证日期

      if (isEmpty(data)) {
        this.clearInfo();
        const { data: realNameData } = await getRealNameInfoApi();
        data.idCard = realNameData.idCard;
        data.idCardBackUrl = realNameData.idCardBackUrl;
        data.idCardFrontUrl = realNameData.idCardFrontUrl;
        data.name = realNameData.name;
        data.idCardStartDate = +realNameData.idCardStartDate;
        data.idCardEndDate = +realNameData.idCardEndDate;
        data.idCardIssueOrganization = realNameData.idCardIssueOrganization;
        data.selfImageUrl = realNameData.selfImageUrl;
        data.userId = realNameData.userId;
        data.address = realNameData.address;
      }

      const fullData = {
        ...initData(),
        ...data,
        driverLicenseAnnualReviewDate: parseTime(data.driverLicenseAnnualReviewDate),
        driverLicenseInitialIssueDate: parseTime(data.driverLicenseInitialIssueDate),
        qualificationLicenseInitialIssueDate: parseTime(data.qualificationLicenseInitialIssueDate),
        authStatus: data.authStatus === null ? DriverAtuhStatus.Unknow : data.authStatus,
        reasonKeys: data?.refuseReason?.map(item => item.item) || [],
        qualificationLicenseDriveType: !data.qualificationLicenseDriveType
          ? []
          : data.qualificationLicenseDriveType.split(',')
      };

      this.updateInfo(fullData);

      // 只有认证通过修改才需要比较
      if (this.isAmendDriverAuthInfo) {
        const { step, driverId, id, authStatus, issueDate, ...fieldsMap } = fullData;
        this.originVerifiedInfo = cloneDeep(fieldsMap);
      }

      switch (this.verifiedInfo.authStatus) {
        case DriverAtuhStatus.Unknow:
          break;
        case DriverAtuhStatus.Waiting:
          this.verifiedInfo.step = 3;
          break;
        case DriverAtuhStatus.Finish:
          this.verifiedInfo.step = 3;
          break;
        case DriverAtuhStatus.Fail:
          this.verifiedInfo.step = 3;
          break;
      }

      typeof callback === 'function' && callback();
    }
  }
});
